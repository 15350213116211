@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.openlayers-map {
	height: 100vh;
}

.navControl {
  position: absolute;
  right: 10px;
  top: calc(50% - 15px);
}

.mapboxgl-ctrl-group button {
	width: 40px;
    height: 40px;
    background-size: 100%;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%2300B4FF'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%2300B4FF'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
}

button.btn-primary, a.btn-primary {
	margin: 10px 5px 0px;
	background-color: rgba(0, 180, 255, 1);
	border: 0;
	box-shadow: 2px 4px 8px 0px rgba(102, 113, 128, 1);
}

button.btn-primary:hover, a.btn-primary:hover, button.btn-primary:focus, a.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
	background-color: rgba(102, 113, 128, 1)!important;
}

.btn-primary.disabled, .btn-primary:disabled {
	background-color: rgba(102, 113, 128, 1)!important;
}

.dropdown-basic-button .dropdown-toggle {
	width: 100%;
    margin-left: 0;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-basic-button .dropdown-toggle::after {
	position: absolute;
    right: 10px;
    top: calc(50% + 2px);
}

.dropdown-item.active, .dropdown-item:active {
	background-color: rgba(0, 180, 255, 1)!important;
}

.input-group-prepend .input-group-text {
	background-color: rgba(0, 180, 255, 1);
	color: #fff;
	border: 1px solid rgba(0, 180, 255, 1);
}

.form-caption {
	margin-left: 5px;
	font-size: 18px;
}

input.form-control {
	border: 1px solid rgba(0, 180, 255, 1);
}

.modal-buttons-wrap {
	text-align: center;
}

.map-button-wrapper {
	position: fixed;
	bottom: 15px;
	text-align: center;
	width: 300px;
	left: calc(50% - 150px);
}

.map-button-wrapper button {
	margin: 5px 5px;

}

.address-popup {
	text-align: center;
	font-size: 20px;
	margin: 5px 10px;
}

.popup-buttons {
	width: 100%;
	text-align: center;
}

.popup-buttons button {
	width: 80px;
}

.modal-header {
	text-align: center;
}

.modal-title {
	text-transform: uppercase;
	color: rgb(102, 113, 128);
	font-weight: bold;
	margin: 0 auto;
}

.modal-conent {
	margin: 15px 0px;
}

@media all and (min-width: 425px) {
	.modal-conent {
		margin: 15px 20px;
	}
}

.modal-conent .video-block {
	margin-top: 50px;
}

.modal-conent .video-block h3 {
	margin-bottom: 30px;
}

.modal-conent--center {
	text-align: center;
}

.modal-conent .buttons-block {
	margin-top: 35px;
}

.modal-conent .buttons-block button {
	margin: 0 5px;
}

.modal-content .modal-form {
	text-align: left;
}

.map-button-wrapper.block-bg {
	background-color: #fff;
	padding: 5px;
	border-radius: 10px;
}

.back-button-wrapper {
	position: fixed;
	top: 15px;
	left: 15px;
	z-index: 9999;
}

.sp-options-wrapper {
	position: fixed;
	top: 65px;
	left: 15px;
	width: 150px;
	z-index: 9999;
}

.tutorial-button-wrapper {
	position: fixed;
	bottom: 30px;
	right: 15px;
}

@media all and (max-width: 425px) {
	.sp-options-wrapper {
		top: 90px;
	}

	.tutorial-button-wrapper {
		bottom: 70px;
		right: 5px;
	}
}

.map-marker {
	user-drag: none; 
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.radio-button {
	position: absolute!important;
	top: 0;
}

.radio-button-wrap input[type="radio"] {
	opacity: 0;
	pointer-events: 0;
	position: absolute;
	top: 0;
}

.radio-block {
	text-align: center;
}

.radio-block label {
	text-transform: uppercase;
}

.radio-block-inner {
	position: relative;
	width: 135px;
	height: 135px;
	overflow: hidden;
	border: 3px solid #fff;
	margin: 5px auto;
	border-radius: 15px;
}

input[type="radio"]:checked ~ * .radio-block-inner {
	border: 3px solid rgba(0, 180, 255, 1);
}

.radio-block-inner label {
	opacity: 0;
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.radio-block-inner img {
	width: 100%;
}

.map-heading-wrapper {
	position: fixed;
	top: 15px;
	width: 300px;
	left: calc(50% - 150px);
	text-align: center;
	background-color: #fff;
	padding: 10px 5px;
	border-radius: 5px;
	font-size: 18px;
	box-shadow: 2px 4px 8px 0px rgba(102, 113, 128, 1);
	font-weight: bold;
	text-transform: uppercase;

}

@media all and (max-width: 425px) {
	.map-heading-wrapper {
		width: 290px;
		right: 5px;
		left: auto;
	}
}

.map-heading-wrapper p {
	margin: 0;
	padding: 0;
	color: rgb(102, 113, 128);
}

.input-error {
  background-color: #ff000038!important;
}

.kwh-value {
	font-weight: 800;
	font-size: 26px;
	color: rgba(0, 180, 255, 1);
}

.circle-chart {
	position: fixed;
	top: 15px;
	right: 15px;
	z-index: 99999;
	width: 125px;
	height: 125px;
}

@media all and (max-width: 425px) {
	.circle-chart {
		top: 110px;
	}
}

.form-check-input.input-error ~ label {
	color: red;
}

#errorBlock {
	width: 100%;
	text-align: center;
	margin-top: 100px;
}




.proposal-tabs {
	margin: 30px 0; 
	background-color: #40b4fb;
}

.proposal-tab-wrap {
	transition: .4s;
}

.proposal-tab-wrap p {
	color: #fff;
}

.proposal-tab-wrap.current, .proposal-tab-wrap:hover {
	background-color: #fff;
}

.proposal-tabs a {
	text-decoration: none!important;
}

.proposal-tabs p {
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}

.proposal-tab-wrap.current p, .proposal-tab-wrap:hover p {
	color: #000;
}

.proposal-tab {
	text-align: center;
	height: 40px;
}

.proposal-heading {
	text-align: center;
	margin: 25px 0 20px;
}

.proposal-page {
	color: #484848;
}

.proposal-heading h2 {
	font-size: 34px;
	color: #484848;
}

.pricing-blocks {
	text-align: center;
}

.pricing-block {
	text-align: center;
	border: 2px solid #d8d8d8;
	max-width: 400px;
	padding: 30px 30px;
	margin: 5px 20px 25px;
	display: inline-block;
	vertical-align: top;
}

.pricing-block_heading, .benefits-text_header {
	font-size: 24px;
}

.pricing-block_caption {
	color: #8c8c8c;
	font-size: 16px;
	font-style: italic;
}

.pricing-block_remark {
	margin-top: 10px;
	font-size: 14px;
	font-style: italic;
	color: #8c8c8c;
	text-align: left;
}

.pricing-block_price {
	font-size: 34px;
	font-weight: 600;
}

.pricing-block_price span {
	font-size: 20px;
	font-weight: 400;
}

.pricing-block button {
	width: 200px;
	height: 50px;
	border-radius: 0;
	background-color: #40b4fb;
	border: 0;
}

.benefits-block {
	text-align: center;
	height: 70vh;
}

.benefits-block .benefits-block_money {
	margin-top: 60px;
	font-size: 32px;
	color: #00b76b;
	font-weight: 500;
}

.benefits-text .benefits-text_money {
	font-size: 32px;
	color: #00b76b;
	font-weight: 500;
}

.benefits-block .benefits-block_money span {
	font-size: 28px;
	font-weight: 400;
}

.benefits-text {
	max-width: 400px;
	margin: 25px auto;
}

.benefits-text p {
	margin: 25px 0;
}


.error-message {
	text-align: center;
	font-size: 20px;
}














